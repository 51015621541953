/*
================================================================
IMPORTS
================================================================
*/

var _ = require('underscore');

import 'lazysizes';

var tm_body = document.querySelector('body');
var tm_html = document.querySelector('html');


// const Marquee3k = require('marquee3000');

var Flickity = require('flickity');

import gsap from "gsap";
// import CustomEase from 'gsap/CustomEase';
import barba from '@barba/core';

import artistCalendar from './calendar';

/*
================================================================
LOGO ANIMATION
================================================================
*/

var tm_clwb = document.getElementById('clwb-bar');
var tm_music = document.getElementById('music-bar');

function jigTheLogo(){
    let a = Math.random() * (10 - -10) + -10;
    let b =  Math.random() * (10 - -10) + -10;
    let c =  Math.random() * (10 - -10) + -10;
    let d =  Math.random() * (10 - -10) + -10;
    tm_clwb.style.transform = "rotate(" + a + "deg) translate(" + b + "px)";
    tm_music.style.transform = "rotate(" + c + "deg) translate(" + d + "px)";
}

/*
================================================================
ACCESSIBILITY
================================================================
*/

(function () {
    'use strict';

    function keyboardFocus (e) {
        if (e.keyCode !== 9) {
            return;
        }

        switch (e.target.nodeName.toLowerCase()) {
            case 'input':
            case 'select':
            case 'textarea':
                break;
            default:
                document.documentElement.classList.add('keyboard-focus');
                document.removeEventListener('keydown', keyboardFocus, false);
        }
    }

    document.addEventListener('keydown', keyboardFocus, false);
})();


/*
================================================================
COOKIE CONSENT
================================================================
*/
var lang = document.documentElement.lang;

var cc = initCookieConsent();

// run plugin with config object
cc.run({
	autorun : true, 
	delay : 0,
	current_lang : lang,
	auto_language : false,
	autoclear_cookies : true,
	cookie_expiration : 365,
	force_consent: false,
    gui_options: {
        consent_modal : {
            transition: 'slide'             // zoom/slide
        },
        settings_modal : {
            layout : 'bar',                 // box/bar
            // position : 'left',           // left/right
            transition: 'slide'             // zoom/slide
        }
    },
	onAccept: function(cookie){
		console.log("onAccept fired ...");
		
		if(cc.allowedCategory('analytics')){
			cc.loadScript('https://www.googletagmanager.com/gtag/js?id=G-MZRD07CTKR', function(){		
				window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-MZRD07CTKR');
				gtag('send', 'pageview');
				console.log("analytics loaded");
			});
		}
        if(cc.allowedCategory('analytics')){
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1005855730193938');
            fbq('track', 'PageView');
            console.log("pixel loaded");
        }

		// delete line below
		typeof doDemoThings === 'function' && doDemoThings(cookie);
	},

	onChange: function(cookie){
		console.log("onChange fired ...");
		
		// delete line below
		typeof doDemoThings === 'function' && doDemoThings(cookie);
	},

	languages : {
		'en' : {	
			consent_modal : {
				title :  "Cookies",
				description :  'This site uses cookies. For more information please read our <a href="/privacy">privacy policy.</a>',
				primary_btn: {
					text: 'Accept',
					role: 'accept_all'				//'accept_selected' or 'accept_all'
				},
				secondary_btn: {
					text : 'Settings',
					role : 'settings'				//'settings' or 'accept_necessary'
				}
			},
			settings_modal : {
				title : '<div>Cookie settings</div><div aria-hidden="true" style="font-size: .8em; font-weight: 200; color: #687278; margin-top: 5px;">Powered by <a tabindex="-1" aria-hidden="true" href="https://github.com/orestbida/cookieconsent/" style="text-decoration: underline;">cookie-consent</a></div>',
				save_settings_btn : "Save settings",
				accept_all_btn : "Accept all",
				close_btn_label: "Close",
				cookie_table_headers : [
					{col1: "Name" }, 
					{col2: "Domain" }, 
					{col3: "Expiration" }, 
					{col4: "Description" }, 
					{col5: "Type" }
				],
				blocks : [
					{
						title : "Cookie usage",
						description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
					},{
						title : "Strictly necessary cookies",
						description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
						toggle : {
							value : 'necessary',
							enabled : true,
							readonly: true							//cookie categories with readonly=true are all treated as "necessary cookies"
						}
					},{
						title : "Preferences cookies",
						description: 'These cookies allow the website to remember the choices you have made in the past',
						toggle : {
							value : 'preferences',	//there are no default categories => you specify them
							enabled : false,
							readonly: false
						}
					},{
						title : "Analytics cookies",
						description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
						toggle : {
							value : 'analytics',
							enabled : false,
							readonly: false
						},
						cookie_table: [
							{
								col1: '_ga',
								col2: 'google.com',
								col3: '2 years',
								col4: 'description ...' ,
								col5: 'Permanent cookie'
							},
							{
								col1: '_gat',
								col2: 'google.com',
								col3: '1 minute',
								col4: 'description ...' ,
								col5: 'Permanent cookie'
							},
							{
								col1: '_gid',
								col2: 'google.com',
								col3: '1 day',
								col4: 'description ...' ,
								col5: 'Permanent cookie'
							}
						]
					},{
						title : "More information",
						description: 'For any queries in relation to my policy on cookies and your choices, please <a class="cc-link" href="https://orestbida.com/contact">contact me</a>.',
					}
				]
			}
		},
        'cy' : {	
			consent_modal : {
				title :  "Welsh Cookies",
				description :  'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
				primary_btn: {
					text: 'Accept',
					role: 'accept_all'				//'accept_selected' or 'accept_all'
				},
				secondary_btn: {
					text : 'Reject',
					role : 'accept_necessary'				//'settings' or 'accept_necessary'
				}
			},
			settings_modal : {
				title : '<div>Cookie settings</div><div aria-hidden="true" style="font-size: .8em; font-weight: 200; color: #687278; margin-top: 5px;">Powered by <a tabindex="-1" aria-hidden="true" href="https://github.com/orestbida/cookieconsent/" style="text-decoration: underline;">cookie-consent</a></div>',
				save_settings_btn : "Save settings",
				accept_all_btn : "Accept all",
				close_btn_label: "Close",
				cookie_table_headers : [
					{col1: "Name" }, 
					{col2: "Domain" }, 
					{col3: "Expiration" }, 
					{col4: "Description" }, 
					{col5: "Type" }
				],
				blocks : [
					{
						title : "Cookie usage",
						description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
					},{
						title : "Strictly necessary cookies",
						description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
						toggle : {
							value : 'necessary',
							enabled : true,
							readonly: true							//cookie categories with readonly=true are all treated as "necessary cookies"
						}
					},{
						title : "Preferences cookies",
						description: 'These cookies allow the website to remember the choices you have made in the past',
						toggle : {
							value : 'preferences',	//there are no default categories => you specify them
							enabled : false,
							readonly: false
						}
					},{
						title : "Analytics cookies",
						description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
						toggle : {
							value : 'analytics',
							enabled : false,
							readonly: false
						},
						cookie_table: [
							{
								col1: '_ga',
								col2: 'google.com',
								col3: '2 years',
								col4: 'description ...' ,
								col5: 'Permanent cookie'
							},
							{
								col1: '_gat',
								col2: 'google.com',
								col3: '1 minute',
								col4: 'description ...' ,
								col5: 'Permanent cookie'
							},
							{
								col1: '_gid',
								col2: 'google.com',
								col3: '1 day',
								col4: 'description ...' ,
								col5: 'Permanent cookie'
							}
						]
					},{
						title : "More information",
						description: 'For any queries in relation to my policy on cookies and your choices, please <a class="cc-link" href="https://orestbida.com/contact">contact me</a>.',
					}
				]
			}
		}
	}
});

/*
================================================================
NAVIGATION
================================================================
*/

var toggle = document.querySelector('#menu-toggle-button');
var menu = document.querySelector('.main-menu-container');
var menuClose = document.querySelector('.menu-close');


toggle.addEventListener('click', toggleMenu);
menuClose.addEventListener('click', toggleMenu);

function toggleMenu(){
    if (menu.classList.contains('is-active')) {
        tm_body.classList.remove('menu--open');
        toggle.setAttribute('aria-expanded', 'false');
        menuClose.setAttribute('aria-expanded', 'false');
        menu.classList.remove('is-active');
    } else {
        tm_body.classList.add('menu--open');
        menu.classList.add('is-active'); 
        toggle.setAttribute('aria-expanded', 'true');
        menuClose.setAttribute('aria-expanded', 'true');
    }
}

function closeMenu(){
    tm_body.classList.remove('menu--open');
    toggle.setAttribute('aria-expanded', 'false');
    menuClose.setAttribute('aria-expanded', 'false');
    menu.classList.remove('is-active');
}

/*
================================================================
HEAD SHRINKER
================================================================
*/


var prevScrollPos = 0;

function scrollEvent(){
	
    var scroll = window.scrollY;
    if(scroll > 100 && scroll > prevScrollPos){
        tm_body.classList.add('shrink');
    }else if(scroll < prevScrollPos){
        tm_body.classList.remove('shrink');
    }
	// console.log(scroll);
    prevScrollPos = window.scrollY;
}

document.addEventListener("scroll", scrollEvent); 


/*
================================================================
CLICKING
================================================================
*/

// Listen to all clicks on the document
document.addEventListener('click', function (event) {
	jigTheLogo();
	if (event.target.classList.contains('filter-button')){
		filterFunction(event.target);
		event.preventDefault();
	}
}, false);



/*
================================================================
ARTIST CALENDARS
================================================================
*/

function calendarInit(){
    const calendars = document.querySelectorAll(".calendar");
    for (var i = 0; i < calendars.length; i++) {
        const calendar = new artistCalendar(calendars[i]);
    }    
}

calendarInit();


/*
================================================================
UPDATE LANGUAGE LINK
================================================================
*/

function updateLanguageLink(){
	let link = document.querySelector(".main-wrapper").dataset.lang;
	let text = document.querySelector(".main-wrapper").dataset.langname;
	document.querySelector(".language-toggle-button a").href = link;
	document.querySelector(".language-toggle-button a").innerHTML = text;
	console.log(link);
}


/*
================================================================
SLIDESHOW
================================================================
*/

function initSlideshows(){
	const slideshows = document.querySelectorAll(".homepage-carousel");
    for (var i = 0; i < slideshows.length; i++) {
		var elem = slideshows[i];
		var args = { "pageDots": false, "prevNextButtons": false, "autoPlay": 4000, "wrapAround": true, "pauseAutoPlayOnHover": false};
		var flkty = new Flickity( elem, args);
	} 
}




/*
================================================================
ARTIST FILTER SYSTEM
================================================================
*/

function filterFunction(target){
	const category = target.dataset.filter;
	const description = target.dataset.description;
	const textContainer = document.querySelector(".category-description");
	const filters = document.querySelectorAll(".filter-button");
	for (var i = 0; i < filters.length; i++) {
		if(filters[i].dataset.filter != category){
			filters[i].classList.remove('active');
		}
	}
	textContainer.innerHTML = description;
	console.log(target.classList);
	if(target.classList.contains('active')){
		clearFilters();
		target.classList.remove('active');
	}else{
		filterArtists(category);
		target.classList.add('active');
	}
}

function filterArtists(category){
	// console.log(category);
	const artists = document.querySelectorAll(".artist-item");
    for (var i = 0; i < artists.length; i++) {
        if(artists[i].classList.contains(category)){
			artists[i].classList.remove('hidden');
		}else{
			artists[i].classList.add('hidden');
		}
    }    
}

function clearFilters(){
	document.querySelector(".category-description").innerHTML = "";
	const artists = document.querySelectorAll(".artist-item");
    for (var i = 0; i < artists.length; i++) {
		artists[i].classList.remove('hidden');
	}
}


/*
================================================================
TRANSITION STUFF
================================================================
*/

function setHeight(target){
	let height = window.scrollY + window.innerHeight;
	console.log(height);
	target.style.height = height;
}

/*
================================================================
BARBA
================================================================
*/

if (history.scrollRestoration) {
    history.scrollRestoration = 'manual';
  }

const loadingMask = document.getElementById('loading-mask');

const outRight = "100%";
const outLeft = "-100%";

// CustomEase.create("ease", "0.4, 0.0, 0.2, 1");

barba.init({
	prevent: ({ el }) => el.classList && el.classList.contains('prevent'),
    schema: {
        prefix: 'data-page',
        wrapper: 'wrapper'
    },
    views: [{
        namespace: 'home',
        beforeEnter() {
            initSlideshows();
        },
        afterEnter() {
            
        }
    }],
    transitions: [{
    name: 'left-right',
    sync: true,
    // leave(data) {
    //     return gsap.fromTo(loadingMask, {
    //         top: "-100vh"
    //     },{
    //         top: 0,
    //         duration: .6,
    //         ease: "power4.inOut"
    //     });
    // },
    // enter(data) {
    //     return gsap.fromTo(loadingMask, {
    //         top: 0
    //     },{
    //         top: "100vh",
    //         duration: .6,
    //         ease: "power4.inOut"
    //     });
    // }
	// sync: true,
        // from: {
        //     custom: (data) => {
        //         return data.current.container.dataset.order < data.next.container.dataset.order;
        //       }
        // },
        leave(data) {
            return gsap.fromTo(data.current.container, {
            	top: "0%"
            },{
            top: "-100vh",
            duration: .5,
            ease: "power4.inOut"
            });
		},
        enter(data) {
            return gsap.fromTo(data.next.container, {
				top: "100vh"
            },{
            top: "0%",
            duration: .5,
            ease: "power4.inOut"
            });
        }
    }]
});

barba.hooks.beforeLeave((data) => {
	setHeight(data.current.container);
    closeMenu();
  });

barba.hooks.beforeEnter((data) => {
	data.next.container.classList.add('in-transit');
    jigTheLogo();
});

barba.hooks.afterEnter((data) => {
	data.next.container.classList.remove('in-transit');
	window.scrollTo(0, 0);
    calendarInit();
});

barba.hooks.after((data) => {
	updateLanguageLink();
});

