// const artist = "379603";

// const xhr = new XMLHttpRequest();
// xhr.onreadystatechange = () => {
//   if (xhr.readyState === XMLHttpRequest.DONE) {
//     console.log(typeof xhr.responseText);
//     console.log(xhr.responseText);
//   }
// };
// xhr.open('GET', 'https://api.songkick.com/api/3.0/artists/' + artist + '/calendar.json?apikey=gJDj3MCyJqwuVZ1B', true);
// xhr.setRequestHeader('Accept', 'application/json');
// xhr.send(null);

// if (xhr.readyState === XMLHttpRequest.DONE) {
//     const res = JSON.parse(xhr.responseText);
//     console.log(res);
//   };


  export default class artistCalendar {
    
    constructor(el) {
            this.DOM = {el: el};
            let artist = el.dataset.artist;
            let name = el.dataset.name;
            let tickets = el.dataset.text;
            this.loadJSON(artist, name, tickets);
    }


    loadJSON(artist, name, tickets) {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            const res = JSON.parse(xhr.responseText);
            const events = res.resultsPage.results.event;
            for (var i = 0; i < events.length; i++) {
                let event = events[i];
                // console.log(events[i]);
                let date = new Date(event.start.date);
                const day = date.getDate();
                const year = date.getFullYear();
                const months = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ];
                const monthIndex = date.getMonth();
                const monthName = months[monthIndex];
                let displayDate = `${day} ${monthName} ${year}`;
                let title = event.displayName;
                let series = event.series;
                let venue = event.venue.displayName;
                let location = event.location.city;
                let uri = event.uri;
                let html = '';
                html += '<dl class="event-listing page-grid col-spaced">';
                html += '<dt class="event-date xs-col-12 md-col-4">' + displayDate + '</dt>';
                html += '<dd class="event-details xs-col-12 md-col-8">';
                html += '<div class="event-details-inner">';
                html += '<h4 class="event-title">' + name + '</h4>';
                if(series){
                    html += '<p class="series-title">' +  series.displayName + '</p>';
                }
                if(venue){
                html += '<p class="event-venue">' + venue + '</p>';
                }
                if(location){
                html += '<p class="event-location">' + location + '</p>';
                }
                html += '</div>';
                if(uri){
                    html += '<p class="event-tickets"><a class="button link-button">' + tickets + '</a></p>';
                }
                html += '</dd>';
                html += '</dl>';
                this.DOM.el.insertAdjacentHTML('beforeend', html);
            }
        }
        };
        xhr.open('GET', 'https://api.songkick.com/api/3.0/artists/' + artist + '/calendar.json?apikey=gJDj3MCyJqwuVZ1B', true);
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.send(null);      
    }

}